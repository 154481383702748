//
// Aside
//




// Aside
.kt-aside {
	background-color: kt-get($kt-aside-config, base, default, bg-color);
	width: kt-get($kt-aside-config, base, default, width);
	transition: kt-get($kt-aside-config, base, minimize, transition);

	// Scrollbar
	@include kt-perfect-scrollbar-skin(#637099);

	// Menu
	.kt-aside-menu {
		margin: 0;
		padding: 15px 0;

		.ps__rail-y {
			right: 2px;
		}
	}
}

// Desktop Mode
@include kt-desktop {
	.kt-aside {
		// Menu
		.kt-aside-menu {
			.kt-menu__nav {
				> .kt-menu__item {
					// Active state

					&.kt-menu__item--here,
					&.kt-menu__item--active {
						$kt-aside-menu-item-height: kt-get($kt-aside-config, menu, build, layout, default, item, link, self, height);

						> .kt-menu__link {
							&:after {
								content: " ";
								position: absolute;
								right: 0;
								top: 0;
								display: block;
								width: 0;
								height: 0;
								border-style: solid;
								border-width: $kt-aside-menu-item-height/2 10px $kt-aside-menu-item-height/2 0;
								border-color: transparent $kt-content-bg-color transparent transparent !important;
							}
						}
					}
				}
			}
		}

		// Fixed
		.kt-aside--fixed & {
			position: fixed;
			top: 0;
			bottom: 0;
			left: 0;
			z-index: kt-get($kt-aside-config, base, fixed, zindex);
		}

		.kt-aside--static & {
			position: relative;
			z-index: 1;

			.kt-aside-menu {
				margin-top: kt-get($kt-header-config, base, desktop, default, height);
			}
		}

		.kt-header--fixed.kt-aside--static & {
			padding-top: kt-get($kt-header-config, base, default, height);
		}

		// Minimize
		.kt-aside--minimize & {
			width: kt-get($kt-aside-config, base, minimize, width);
			transition: kt-get($kt-aside-config, base, minimize, transition);
		}

		.kt-aside--minimizing & {
			overflow: hidden;
			
			.kt-aside-menu {
				.kt-menu__nav  {
					width: kt-get($kt-aside-config, base, default, width);
					overflow: hidden;
				}
			}
		}

		// Scroll
		@include kt-perfect-scrollbar-ver-size( kt-get($kt-aside-config, base, fixed, scroll, width) );
	}

	// Fixed & Minimize Aside hover
	.kt-aside--fixed.kt-aside--minimize-hover {
		.kt-aside {
			width: kt-get($kt-aside-config, base, default, width);
			transition: kt-get($kt-aside-config, base, minimize, transition);
		}

		.kt-header {
			transition: kt-get($kt-aside-config, base, minimize, transition);
			left: kt-get($kt-aside-config, base, minimize, width);
		}

		.kt-wrapper {
			transition: kt-get($kt-aside-config, base, minimize, transition);
			padding-left: kt-get($kt-aside-config, base, minimize, width);
		}
	}
}

// Build aside menu
@include kt-menu-ver-build-layout(kt-get($kt-aside-config, menu));
@include kt-menu-ver-build-skin(kt-get($kt-aside-config, menu), default);

// Aside offcanvas panel for mobile mode
@include kt-offcanvas-build(kt-aside, tablet-and-mobile, kt-get($kt-aside-config, base, offcanvas-mobile));