//
// Dropzone
//




// Base
@mixin kt-dropzone-base() {
    .dropzone {
        @include kt-rounded {
            border-radius: $kt-border-radius;
        }
        padding: 20px;
        text-align: center;
        cursor: pointer;

        .dropzone-msg-title {
        	margin: 0 0 5px 0;
        	padding: 0;
        	font-weight: 600;
        	font-size: 1.1rem;
        }

        .dropzone-msg-desc {
        	font-size: 0.9rem;
        }

        .dz-preview {
        	.dz-image {
                @include kt-rounded {
        		  border-radius: $kt-border-radius;
        	    }
            }
        }
    }
}

// Component Skin
@mixin kt-dropzone-skin() {
    .dropzone {
        border: 2px dashed kt-base-color(grey, 2);

        .dropzone-msg-title {
        	color: kt-base-color(label, 3);
        }

        .dropzone-msg-desc {
        	color: kt-base-color(label, 2);
        }
    
        // State colors
        @each $name, $color in $kt-state-colors {
            &.dropzone-#{$name} {
                border-color: kt-get($color, base);
            } 
        }
    }
}

// Base
@include kt-dropzone-base();

// Skin
@include kt-dropzone-skin();